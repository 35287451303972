#board-footer {
  background: none;
  border: 0px;
  padding: 0px;
  position: fixed;
  z-index: 99999;
  display: inline-flex;
  font-size: 1.5em;
  margin: 0.5em;
  cursor: pointer;
  width: fit-content;
  bottom: 0px;
  left: 0px;
  align-items: baseline;
}

#board-footer-url {
  font-size: 0.6em;
}
