// .UserList {
//   // min-height: calc(100vh - 50px);
//   flex: 1;
//   min-height: 0;
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   justify-content: center;
// }

// .loaderWrapper {
//   flex: 1;
//   display: flex;
//   width: 100%;
//   align-items: center;
//   justify-content: center;
// }

// .loader {
//   flex: 1;
//   justify-content: center;
//   flex: 0;
// }
.disabled-line {
  color: lightgray;
}
